import { Container } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";
import AffiliateCodePanel from "./AffiliateCodePanel";
import AffiliateTable from "./AffiliateTable";
import getSubscription from "./getSubscription";
import useSubscriber from "../../hooks/useSubscriber";

const AffiliatePage = () => {
    const { user } = useContext(AuthContext);
    const [affiliates, setAffiliates] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchAffiliateData = async () => {
            setLoading(true);
            const affiliateRef = collection(db, "users");
            const q = query(affiliateRef, where("referral", "==", user.id));

            try {
                const affiliateSnapshot = await getDocs(q);

                let affiliates = [];

                await Promise.all(
                    affiliateSnapshot.docs.map(async (aff) => {
                        const customer = await getSubscription(aff.id);
                        affiliates.push({
                            ...customer,
                            ...aff.data(),
                            date: new Date(aff.data().date?.seconds * 1000 || customer.date),
                        });

                        // Legacy data does not have date field
                        if (!aff.data().date) {
                            const docRef = doc(db, "users", aff.id);
                            if (customer.date) {
                                await updateDoc(docRef, { date: customer.date });
                            } else {
                                await updateDoc(docRef, { date: new Date("2022-12-01") });
                            }
                            console.log("updated");
                        }

                        return Promise.resolve();
                    })
                );
                affiliates.sort((a, b) => b.date - a.date);
                console.log("affiliates= ", affiliates);
                setAffiliates(affiliates);
                setLoading(false);
            } catch (err) {
                console.log(err.message);
                setLoading(false);
            }
        };

        fetchAffiliateData();
    }, []);

    return (
        <Box m={1}>
            <GridContainer>
                <GridFlexBox gap={"8px"}>
                    <Title>Affiliate Page</Title>
                </GridFlexBox>
                <GridDivider />
                <AffiliateCodePanel />
                <GridDivider />
                <Container maxWidth={false}>
                    {loading && <LinearProgress />}
                    {affiliates && <AffiliateTable affiliates={affiliates} setLoading={setLoading} />}
                </Container>
            </GridContainer>
        </Box>
    );
};

export default AffiliatePage;
