import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../utils-functions/convertDate";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import { CustomerInfo, PaymentInfo, SubscriptionInfo, UserType } from "./types";

const DefaultCommissionStructure = {
    "1": 0.2,
    "2": 0.1,
    "3": 0.05,
};

const processData = async (result: any, user: UserType) => {
    const baseUser = result[user?.id];
    console.log(baseUser);
    const baseLevel = baseUser?.level || 1;

    const commissionTable: Record<string, any> = {};

    //
    const processItem = async (key: string) => {
        let obj = {
            ...result[key],
            id: key,
            level: result[key].level - baseLevel,
            totalDirectDownlines: result[key].downlines?.length || 0,
        };

        const email = obj.email || "";
        const customerRef = collection(db, "customers");
        const type = obj.type || "";

        if (type) {
            console.log("obj", obj);
            obj.displayName = obj.description || "";
        }

        try {
            if (email || type) {
                const customerQuery = query(customerRef, where("email", "==", email));
                const customerSnapshot = await getDocs(customerQuery);
                const customers = mapSnapshotTS<CustomerInfo>(customerSnapshot);
                let customer = customers[0] || {};

                if (type) {
                    customer = { ...obj };
                }

                if (customer.id) {
                    // get subscriptions
                    const subscriptionRef = collection(db, "customers", customer.id, "subscriptions");
                    const subscriptionSnap = await getDocs(subscriptionRef);
                    const subscriptions = mapSnapshotTS<SubscriptionInfo>(subscriptionSnap);

                    let type: string | undefined;

                    const subscriptionData = subscriptions.map((subscription) => {
                        // only for active subscriptions
                        if (subscription.status !== "active") return null;

                        if (!type) type = subscription.type;

                        return {
                            id: subscription.id,
                            created: convertDate(subscription.created),
                            status: subscription.status,
                            current_period_start: convertDate(subscription.current_period_start),
                            current_period_end: convertDate(subscription.current_period_end),
                            amount: subscription.items[0].plan.amount / 100,
                            currency: subscription.items[0].plan.currency,
                            interval: subscription.items[0].plan.interval,
                            type: subscription.type || "",
                            description: subscription.description || "",
                            priceId: subscription.priceId || "",
                            productId: subscription.productId || "",
                        };
                    });

                    // get payments
                    const paymentRef = collection(db, "customers", customer.id, "payments");
                    const q = query(paymentRef, orderBy("created", "asc"));
                    const paymentSnap = await getDocs(q);
                    const payments = mapSnapshotTS<PaymentInfo>(paymentSnap);

                    // custom mrr to cater for old subscriptions with discount
                    let customMrr = 0;
                    let paymentData = payments.map((payment) => {
                        // only for subscriptions payments
                        if (payment.prices) {
                            //
                            const amount_received = payment.amount_received / 100;

                            if (amount_received === 99) customMrr = 99;
                            if (amount_received === 69) customMrr = 69;
                            if (amount_received === 62) customMrr = 62;
                            if (amount_received === 9) customMrr = 9;

                            return {
                                id: payment.id,
                                amount: payment.amount / 100,
                                amount_received,
                                amount_refunded: (payment.amount_refunded || 0) / 100,
                                created: convertDate(payment.created),
                                currency: payment.currency,
                            };
                        }
                    });

                    paymentData = paymentData.filter((payment) => payment?.created);

                    const totalPayment = paymentData.reduce(
                        (acc, payment) =>
                            acc + (payment?.amount_received || 0) - (payment?.amount_refunded || 0),
                        0
                    );

                    // get monthly and yearly subscriptions
                    const monthlySubscriptions = subscriptionData.filter(
                        (subscription) => subscription?.interval === "month"
                    );
                    const yearlySubscriptions = subscriptionData.filter(
                        (subscription) => subscription?.interval === "year"
                    );

                    // get sum of monthly and yearly subscriptions
                    const totalMonthlySubscriptions = monthlySubscriptions.reduce(
                        (acc, subscription) => acc + (subscription?.amount || 0),
                        0
                    );
                    const totalYearlySubscriptions = yearlySubscriptions.reduce(
                        (acc, subscription) => acc + (subscription?.amount || 0),
                        0
                    );

                    let mrr = totalMonthlySubscriptions + totalYearlySubscriptions / 12;

                    // custom mrr and active subscriptions
                    if (customMrr && mrr !== 0) mrr = customMrr;

                    // Commission calculation
                    const commissionStructure = obj.commissionStructure || DefaultCommissionStructure;
                    const commissionPercentage = commissionStructure[obj.level.toString()] || 0;
                    const totalCommission = totalPayment * commissionPercentage;

                    // Calculate upline commissions
                    obj.uplineIds.slice(1, obj.uplineIds.length).forEach((upline: any) => {
                        const uplineUser = result[upline];
                        if (!uplineUser) return;

                        const objLevel = obj.level;
                        const uplineLevel = uplineUser.uplineIds.length - baseLevel;
                        const level = objLevel - uplineLevel;

                        const uplineCommissionStructure =
                            uplineUser.commissionStructure || DefaultCommissionStructure;
                        const uplineCommissionPercentage = uplineCommissionStructure[level.toString()] || 0;
                        const uplineCommission = totalPayment * uplineCommissionPercentage;
                        if (commissionTable[uplineUser.id]) {
                            commissionTable[uplineUser.id] += uplineCommission;
                        } else {
                            commissionTable[uplineUser.id] = uplineCommission;
                        }
                    });

                    console.log("commissionTable", commissionTable);

                    const mrc = mrr * commissionPercentage;

                    obj.totalPayment = totalPayment;
                    obj.totalCommission = totalCommission;
                    obj.mrr = mrr;
                    obj.mrc = mrc;
                    obj.customerId = customer.id;
                    obj.type = type || "";

                    paymentData.sort((a, b) => ((b?.created as any) || 0) - ((a?.created as any) || 0));

                    // Add subscriptions and payments to obj
                    obj.subscriptions = subscriptionData;
                    obj.payments = paymentData;
                }
            }
        } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
        }

        return obj;
    };

    const keys = Object.keys(result);

    // rows of users
    const processedData = await Promise.all(keys.map(processItem));

    Object.keys(commissionTable).forEach((key) => {
        const user = processedData.find((item) => item.id === key);
        if (user) user.commission = commissionTable[key];
    });

    console.log("commissionTable", commissionTable);

    return { processedData, commissionTable };
};

export default processData;
