import { CssBaseline, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import Menu from "./components/Menu";
import { AuthContext } from "./context/AuthContext";
import useVersionCheck from "./hooks/useVersionCheck";
import AdminPage from "./pages/AdminPage/WaynePlaygroundPage";
import AffiliatePage from "./pages/AffiliatePage/AffiliatePage";
import UpdateAffiliates from "./pages/AffiliatePage/UpdateAffiliates";
import AppointmentCalenderPage from "./pages/AppointmentCalenderPage/AppointmentCalenderPage";
import AssignedLeadsPage from "./pages/AssignedLeadsPage";
import CalendarPage from "./pages/CalendarPage";
import CasesReport from "./pages/CasesPage/CaseReport";
import CasesPage from "./pages/CasesPage/CasesPage";
import ChatInboxPage from "./pages/ChatInboxPage/ChatInboxPage";
import ClientsPage from "./pages/ClientsPage/ClientsPage";
import DjcFunnelsPage from "./pages/DjcFunnelsPage/DjcFunnelsPage";
import FacebookConnectPage from "./pages/FacebookConnectPage/facebook-connect";
import FacebookLoginPage from "./pages/FacebookLoginPage";
import FoldersManagementPage from "./pages/FoldersPage/FoldersManagementPage";
import FollowUpLeadsPage from "./pages/FollowUpLeadsPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import AffiliateSubmissionsPage from "./pages/FormsPage/AffiliateSubmissionsPage";
import CreateFormPage from "./pages/FormsPage/CreateFormPage";
import FormAffiliatePage from "./pages/FormsPage/FormAffiliatePage";
import FormPage from "./pages/FormsPage/FormPage";
import GoogleConnectPage from "./pages/GoogleConnectPage/GoogleConnectPage";
import HomePage from "./pages/HomePage";
import ImageGenerationPage from "./pages/ImageGenerationPage/ImageGenerationPage";
import ConnectInstagramPage from "./pages/InstagramPage/ConnectInstagramPage";
import LandingPage from "./pages/LandingPage";
import LeadsDashBoardPage from "./pages/LeadsDashboard/LeadsDashBoardPage";
import LeadsFlexboxPage from "./pages/LeadsFlexbox/LeadsFlexboxPage";
import LeadsTablePageV3 from "./pages/LeadsTablePageV3/LeadsTablePageV3";
import ListingPageV2 from "./pages/ListingPageV2/ListingPageV2";
import MessengerInboxPage from "./pages/MessengerInboxPage/MessengerInboxPage";
import NewLeadsTable from "./pages/NewLeadsTable/NewLeadsTable";
import NotFoundPage from "./pages/NotFoundPage";
import GeminiChatPage from "./pages/Playground/GeminiChatPage";
import PlaygroundPage from "./pages/Playground/PlaygroundPage";
import QASystemPage from "./pages/QASystemPage/QASystemPage";
import RecordPage from "./pages/QASystemPage/RecordPage";
import OverviewFlowPage from "./pages/ReactFlowPage/ReactFlowPage";
import SalesOverviewPage from "./pages/Sales/SalesOverviewPage";
import {
    default as ListScheduledMessages,
    default as ListScheduledMessagesPage,
} from "./pages/ScheduledMessages/ListScheduledMessages";
import ListWhatsappMessagesPage from "./pages/ScheduledMessages/ListWhatsappMessages";
import SignInPage from "./pages/SignInPage";
import SignOutPage from "./pages/SignOutPage";
import SignUpPage from "./pages/SignUpPage";
import SignUpTrialPage from "./pages/SignUpPage/SignUpTrialPage";
import SourceLeadsPage from "./pages/SourceLeadsPage";
import StripeCheckoutPage from "./pages/StripeCheckoutPage.jsx/StripeCheckoutPage";
import StripeCheckoutPageEliteone from "./pages/StripeCheckoutPage.jsx/StripeCheckoutPageEliteone";
import ManageTelegramBots from "./pages/TelegramBot/ManageTelegramBots";
import TelegramWebHookGenerator from "./pages/TelegramBot/TelegramWebhookGenerator";
import TelegramConnectPage from "./pages/TelegramConnect/TelegramConnectPage";
import TutorialsPage from "./pages/TutorialsPage/TutorialsPage";
import UploadDataPage from "./pages/UploadDataPage";
import UserCardPage from "./pages/UserCardPage";
import UsersPage from "./pages/UsersPage/UsersPage";
import WhatsappFlowPage from "./pages/WhatsappFlow/WhatsappFlowPage";
import WhatsappFlowUsagePage from "./pages/WhatsappFlow/WhatsappFlowUsage";
import WhatsappMessagePage from "./pages/WhatsappMessagePage";
import BackendDashboard from "./pages/WhatsappWebPage/BackendDashboard";
import WebHookGenerator from "./pages/WhatsappWebPage/WebHookGenerator";
import WhatsappBroadcastPage from "./pages/WhatsappWebPage/WhatsappBroadcastPage";
import WhatsappWebPage from "./pages/WhatsappWebPage/WhatsappWebPage";
import WorkFlowPage from "./pages/WorkflowPage/WorkFlowPage";
import GoogleAIPage from "./tests/GoogleAIPage";
import UploadImageFromUrlPage from "./tests/UploadImageFromUrlPage";
import Waynetest from "./tests/waynetest";
import { ColorModeContext, useMode } from "./theme";

import PaymentsPage from "./pages/AffiliatePage/PaymentsPage";
import IntentionsPage from "./pages/IntentionsPage/IntentionsPage";
import StripeCheckout1193Page from "./pages/StripeCheckoutPage.jsx/StripeCheckout1193Page";
import StripeCheckout119Page from "./pages/StripeCheckoutPage.jsx/StripeCheckout119Page";
import StripeCheckout952Page from "./pages/StripeCheckoutPage.jsx/StripeCheckout952Page";
import SystemPage from "./pages/SystemPage/SystemPage";
import WhatsappTimeReport from "./pages/WhatsappWebPage/WhatsappTimeReport";
import ResellerPage from "./pages/ResellerPage/ResellerPage";

function App() {
    const { user } = useContext(AuthContext);
    const [theme, colorMode] = useMode();

    useEffect(() => {
        const changeFavicon = () => {
            const domain = window.location.hostname;

            let favicon = "/favion.ico"; // Default favicon
            let manifestFile = "/manifest.json"; // Default manifest

            if (domain.includes("explosoftai.com")) {
                favicon = "/CustomFavIcons/favicon-explosoft.ico";
                manifestFile = "/manifest-explosoft.json";
                document.title = "Explosoft AI System";
            } else if (
                domain.includes("pilot.iqiglobal.com") ||
                domain.includes("iqi-pilot.web.app") ||
                domain.includes("iqipilot.com")
            ) {
                favicon = "/CustomFavIcons/favicon-pilot.ico";
                manifestFile = "/manifest-pilot.json";
                document.title = "IQI Pilot";
            } else if (domain.includes("localhost")) {
                document.title = "DJC System";
                favicon = "/favicon.ico";
            } else {
                favicon = "/favicon.ico";
            }

            const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
            link.rel = "icon";
            link.href = favicon;
            document.head.appendChild(link);

            // Dynamically set the manifest link
            const linkManifest = document.querySelector("link[rel*='manifest']");
            linkManifest.href = manifestFile;
        };

        changeFavicon();
    }, []);

    // Check version is the latest, if not refresh the app
    useVersionCheck();

    const displayComponent = useMemo(() => {
        const displayComponent = user ? withUser() : noUser();
        return displayComponent;
    }, [user]);

    return (
        <div>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                        <main className="content">
                            <Menu user={user} />
                            {displayComponent}
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </div>
    );
}

export default App;

// Logged In condition
function withUser() {
    return (
        <Routes>
            {/* Home Page */}
            <Route index element={<HomePage />} />

            {/* Authentication Pages */}
            <Route exact path="/sign-up" element={<SignUpPage />} />
            <Route exact path="/sign-up/:referral" element={<SignUpPage />} />
            <Route path="/sign-up-trial" element={<SignUpTrialPage />} />
            <Route path="/sign-up-trial/:referral" element={<SignUpTrialPage />} />
            <Route path="/sign-out" element={<SignOutPage />} />

            {/* Messaging Pages */}
            <Route exact path="/whatsapp-message-page" element={<WhatsappMessagePage />} />
            <Route exact path="/whatsapp-broadcast-page" element={<WhatsappBroadcastPage />} />
            <Route exact path="/list-scheduled-messages-page" element={<ListScheduledMessagesPage />} />
            <Route exact path="/list-whatsapp-messages-page" element={<ListWhatsappMessagesPage />} />
            <Route exact path="/whatsapp-flow-page" element={<WhatsappFlowPage />} />
            <Route exact path="/whatsapp-time-report" element={<WhatsappTimeReport />} />

            {/* Leads Management Pages */}
            <Route exact path="/source-leads-page" element={<SourceLeadsPage />} />
            <Route exact path="/assigned-leads-page" element={<AssignedLeadsPage />} />
            <Route exact path="/follow-up-leads-page" element={<FollowUpLeadsPage />} />
            <Route exact path="/leads-table-page-v3" element={<LeadsTablePageV3 />} />
            <Route exact path="/leads-flexbox-page" element={<LeadsFlexboxPage />} />
            <Route exact path="/new-leads-page" element={<NewLeadsTable />} />
            <Route exact path="/leads-dashboard-page" element={<LeadsDashBoardPage />} />

            {/* User and Profile Pages */}
            <Route exact path="/user-card-page" element={<UserCardPage />} />
            <Route exact path="/users-page" element={<UsersPage />} />

            {/* Pages for Data and Upload Management */}
            <Route exact path="/upload-data-page" element={<UploadDataPage />} />
            <Route exact path="/upload-image-from-url-page" element={<UploadImageFromUrlPage />} />

            {/* Calendar and Appointment Pages */}
            <Route exact path="/calendar-page" element={<CalendarPage />} />
            <Route path="/appointment-calender" element={<AppointmentCalenderPage />} />

            {/* Overview and Analytics Pages */}
            <Route exact path="/overview-flow-page" element={<OverviewFlowPage />} />
            <Route path="/sales-overview-page" element={<SalesOverviewPage />} />

            {/* Stripe Checkout Pages */}
            <Route exact path="/stripe-checkout-page" element={<StripeCheckoutPage />} />
            <Route exact path="/stripe-checkout-119-page" element={<StripeCheckout119Page />} />
            <Route exact path="/stripe-checkout-952-page" element={<StripeCheckout952Page />} />
            <Route exact path="/stripe-checkout-1193-page" element={<StripeCheckout1193Page />} />
            <Route exact path="/stripe-checkout-page-eliteone" element={<StripeCheckoutPageEliteone />} />
            {/* <Route exact path="/stripe-checkout-199-page" element={<StripeCheckout199Page />} />
            <Route exact path="/stripe-checkout-299-page" element={<StripeCheckout299Page />} />
            <Route exact path="/stripe-checkout-page/:params" element={<StripeCheckoutPage />} />
            <Route exact path="/stripe-checkout-page-freetrial" element={<StripeCheckoutPageFreeTrial />} />
            <Route exact path="/stripe-checkout-page-yearly" element={<StripeCheckoutPageYearly />} />
            <Route exact path="/stripe-checkout-page-premium-6" element={<StripeCheckoutPagePremium6 />} />
            <Route exact path="/stripe-checkout-page-premium-10" element={<StripeCheckoutPagePremium10 />} />
            <Route exact path="/stripe-checkout-page-premium-15" element={<StripeCheckoutPagePremium15 />} /> */}

            {/* Communication and Social Media Pages */}
            <Route path="/facebook-login-page" element={<FacebookLoginPage />} />
            <Route path="/facebook-connect-page" element={<FacebookConnectPage />} />
            <Route path="/connect-instagram-page" element={<ConnectInstagramPage />} />
            <Route path="/telegram-connect-page" element={<TelegramConnectPage />} />
            <Route path="/messenger-inbox-page" element={<MessengerInboxPage />} />

            {/* Bot and Automation Pages */}
            <Route exact path="/telegram-webhook-generator-page" element={<TelegramWebHookGenerator />} />
            <Route exact path="/whatsapp-web-page" element={<WhatsappWebPage />} />
            <Route path="/work-flow-page" element={<WorkFlowPage />} />
            <Route path="/whatsapp-flow-usage-page" element={<WhatsappFlowUsagePage />} />

            {/* Admin and Management Pages */}
            <Route exact path="/backend-dashboard-page" element={<BackendDashboard />} />
            <Route exact path="/admin-page" element={<AdminPage />} />
            <Route exact path="/system-page" element={<SystemPage />} />
            <Route exact path="/folders-management-page" element={<FoldersManagementPage />} />

            {/* Affiliate Management Pages */}
            <Route exact path="/affiliate" element={<AffiliatePage />} />
            <Route exact path="/update-affiliates-page" element={<UpdateAffiliates />} />
            <Route path="/form/:formId" element={<FormAffiliatePage />} />
            <Route path="/form/:formId/:referralCode" element={<FormPage />} />
            <Route path="/form/affiliate-submissions-page" element={<AffiliateSubmissionsPage />} />

            {/* QA and Tutorial Pages */}
            <Route path="/qa-system-page" element={<QASystemPage />} />
            <Route path="/tutorials-page" element={<TutorialsPage />} />

            {/* Creative and Utility Pages */}
            <Route path="/image-generation-page" element={<ImageGenerationPage />} />
            <Route exact path="/playground-page" element={<PlaygroundPage />} />
            <Route path="/records/:recordId" element={<RecordPage />} />

            {/* Case Management */}
            <Route path="/cases-page" element={<CasesPage />} />
            <Route path="/cases-report" element={<CasesReport />} />

            {/* Other Pages */}
            <Route exact path="/chat-inbox-page" element={<ChatInboxPage />} />
            <Route path="/clients-page" element={<ClientsPage />} />
            <Route path="/google-ai-page" element={<GoogleAIPage />} />
            <Route path="/google-connect-page" element={<GoogleConnectPage />} />
            <Route exact path="/djc-funnel-page" element={<DjcFunnelsPage />} />
            <Route path="/list-scheduled-messages-page" element={<ListScheduledMessages />} />
            <Route exact path="/create-form-page" element={<CreateFormPage />} />
            <Route path="/gemini-chat-page" element={<GeminiChatPage />} />
            <Route path="/manage-telegram-bots-page" element={<ManageTelegramBots />} />
            <Route path="/listing-pageV2" element={<ListingPageV2 />} />
            <Route path="/webhook-generator-page" element={<WebHookGenerator />} />
            <Route path="/payments-page" element={<PaymentsPage />} />

            {/* Reseller Page */}
            <Route path="/reseller-page" element={<ResellerPage />} />

            {/* Intention Page */}
            <Route path="/intentions-page" element={<IntentionsPage />} />

            {/* Not Found Page */}
            <Route path="*" element={<NotFoundPage />} />

            {/* Experimental and Testing Pages */}
            <Route path="/waynetest" element={<Waynetest />} />
        </Routes>
    );
}

// Logged out condition
function noUser() {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route exact path="/sign-up" element={<SignUpPage />} />
            <Route exact path="/sign-up/:referral" element={<SignUpPage />} />
            <Route exact path="/sign-in" element={<SignInPage />} />
            <Route exact path="/sign-in/:path/:path2" element={<SignInPage />} />
            <Route path="*" element={<LandingPage />} />
            <Route exact path="forgot-password-page" element={<ForgotPasswordPage />} />
            <Route exact path="/form/:formId/:referralCode" element={<FormPage />} />
            <Route path="/records/:recordId" element={<RecordPage />} />

            <Route path="/sign-up-trial" element={<SignUpTrialPage />} />
            <Route path="/sign-up-trial/:referral" element={<SignUpTrialPage />} />
        </Routes>
    );
}
